import { useKeycloak } from '@react-keycloak/web'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import ExternalSession from './ExternalSession'
import LoginPage from './LoginPage'
import { PrivateRoute } from './PrivateRoute'

export const AppRouter = () => {
    const {initialized} = useKeycloak()

    if (!initialized) {
        return <div>Загрузка...</div>
    }

    return (
        <Router>
            <Switch>
                <PrivateRoute exact path="/external-session/:sessionId" component={ExternalSession}/>
                <Route path="/login" component={LoginPage}/>
                <Redirect to="/login"/>
            </Switch>
        </Router>
    )
}
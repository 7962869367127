import * as React from 'react'
import './LoginPage.css'
import './Button.css'

const HR_PLATFORM_URL = process.env.REACT_APP_HR_PLATFORM ?? ''

const LoginPage = () => {
    return (
        <div>
            <div>Вы не аутентифицированы в HR-платформе или была использована невалидная ссылка для начала игры.</div>
            <div>Авторизуйтесь в HR-платформе и перейдите из нее по ссылке для начала игры.</div>
            <div className="btn-container">
                <a href={HR_PLATFORM_URL}>HR-платформа</a>
            </div>
        </div>
    )
}

export default LoginPage
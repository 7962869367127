import { useKeycloak } from '@react-keycloak/web'
import useAxios from 'axios-hooks'
import * as React from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { isAndroid, isIOS } from 'react-device-detect'

const BASE_URL: string = process.env.REACT_APP_BACKEND_URL ?? ''
const WEB_CLIENT_URL: string = process.env.REACT_APP_WEB_CLIENT ?? ''

const ExternalSession = () => {
    const {keycloak} = useKeycloak()

    const {sessionId} = useParams<{ sessionId?: string }>()

    const [{data, loading, error}] = useAxios(
        {
            url: `${BASE_URL }/external/auth`,
            method: 'POST',
            data: {
                assessmentId: sessionId,
                refreshToken: keycloak.refreshToken,
                sub: keycloak.tokenParsed?.sub,
                token: keycloak.token
            }
        }
    )

    if (loading) {
        return (<div>Идет переадресация...</div>)
    }

    if (error) {
        console.error(error.response?.data.message)
        return (<Redirect to="/login" />)
    }

    if (isIOS || isAndroid) {
        return (
            <div>
                <a href={`alabugatrucks://app?code=${data}`}>Запустить игру</a>
            </div>
        )
    }

    window.location.assign(`${WEB_CLIENT_URL}/?code=${data}`)
    return null;
}

export default ExternalSession;